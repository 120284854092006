import { Skeleton } from "antd";

const CapabilitiesCard = ({ title, capabilities, loading}) => {
  return (
    <div className="bg-white mt-1 px-6 py-2 border-b border-gray-200 rounded-md">
      <Skeleton loading={loading} active paragraph={false}>
        <h4 className="py-2 relative text-md leading-4 text-gray-500">
          {title}
        </h4>
      </Skeleton>

      <ul className="divide-y divide-gray-200">
      <Skeleton className="mt-7" title={false} paragraph={{rows: 9}} loading={loading}>
        {capabilities?.map((capability) => {
          return (
            <li
              key={capability.id}
              className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
            >
              <p className="text-sm text-gray-500 truncate">
                {capability.text}
              </p>
            </li>
          );
        })}
        </Skeleton>
      </ul>
    </div>
  );
};

export default CapabilitiesCard;
