const ButtonLarge = ({ text, disabled }) => {
  const color = disabled
    ? "bg-gray-400 hover:bg-gray-400 focus:ring-gray-400"
    : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";

  return (
    <button
      type="button"
      disabled={disabled}
      className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${color}`}
    >
      {text}
    </button>
  );
};

export default ButtonLarge;
