const languageIcons = {
    'en': '🇬🇧',
    'de': '🇩🇪'
}
export const getLanguageIcon = (language) => {
    return languageIcons[language] || ''
}

export const COMPANY_DOMAINS = {
    AGILLIC: 'agillic.com',
    BLUPLANET: 'bluplanet.com'
}

export const getCompanyDomain = (email) => email.split('@')[1]