import { useSelector } from "react-redux";
import { Disclosure } from "@headlessui/react";
import TopNav from "./navigation/TopNav";
import { useAuth } from "../hooks/useAuth";

const navigation = [
  // { name: "Home", href: "/", current: true },
  // { name: "Prospects", href: "/prospectlist", current: false },
  // { name: "New Prospect", href: "/newprospect", current: false },
];
const userNavigation = [
  { name: "Sign out", href: "/login" },
];

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const Layout = (props) => {
  const { isLoggedIn } = useAuth()
  const user = useSelector((state) => state.users.user || {})

  return (
    <>
      {isLoggedIn && <Disclosure as="nav" className="bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            <TopNav
              navigation={navigation}
              userNavigation={userNavigation}
              user={user}
              classNames={classNames}
              open={open}
            />
            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.photoURL}
                      alt=""
                      referrerPolicy="no-referrer"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user.displayName}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>}
      
      <main className={`py-10 bg-gray-100 ${ isLoggedIn ? 'nav' : ''}`}>
        {/* Replace with your content */}
        {props.children}
        {/* /End replace */}
      </main>
    </>
  );
};

export default Layout;
