import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn, setUser } from "../store/usersSlice";
import API from "../utils/firebaseAPI";

export const useAuth = () => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return {
    isLoggedIn,
    login() {
      return API.login().then(
        ({ user: { photoURL, displayName, email, uid } }) => {
            const user = { displayName, photoURL, email, uid }

            dispatch(setUser({ user }))
            dispatch(setIsLoggedIn({ isLoggedIn: true }))
            navigate('/')
        }
      )
    },
    logout() {
      dispatch(setUser({ user: null }))
      dispatch(setIsLoggedIn({ isLoggedIn: false }))
    }
  };
}