import ProspectList from "../components/lists/ProspectList";
import { useEffect, useState } from "react";
import API from "../utils/firebaseAPI";
import { useDispatch } from "react-redux";
import {
  setProspects,
  setProviders,
  setQuestions,
} from "../store/prospectsSlice";
import { useSelector } from "react-redux";
import CreateProspectModal from "../components/overlays/CreateProspectModal";
import Select from "../components/forms/Select";
import { COMPANY_DOMAINS, getCompanyDomain } from "../utils/common";

const FILTER_TYPES = {
  SHOW_ALL: 0,
  SHOW_CREATED_BY_ME: 1,
  SHOW_CREATED_BY_BLUPLANET: 2,
  SHOW_WAITING: 3,
  SHOW_DONE: 4
}

const getFilterOptions = (showAllOptions) => {
  const agillicOnlyOptions = [
    {id: FILTER_TYPES.SHOW_CREATED_BY_BLUPLANET, text: 'Show created by bluplanet'}
  ]
  return [
    {id: FILTER_TYPES.SHOW_ALL, text: 'Show all'},
    {id: FILTER_TYPES.SHOW_CREATED_BY_ME, text: 'Show created by me'},
    ...(showAllOptions ? agillicOnlyOptions : []),
    {id: FILTER_TYPES.SHOW_WAITING, text: 'Show waiting'},
    {id: FILTER_TYPES.SHOW_DONE, text: 'Show done'},
  ]
}

const getFilteredProspects = (allProspects, criteria, user) => {
  switch (criteria) {
    case FILTER_TYPES.SHOW_CREATED_BY_ME:
      return allProspects.filter(prospect => prospect.createdBy.userUid === user.uid)
    case FILTER_TYPES.SHOW_CREATED_BY_BLUPLANET:
      return allProspects.filter(prospect => prospect.createdBy.companyDomain === COMPANY_DOMAINS.BLUPLANET)
    case FILTER_TYPES.SHOW_WAITING:
      return allProspects.filter(prospect => !prospect.completed)
    case FILTER_TYPES.SHOW_DONE:
      return allProspects.filter(prospect => prospect.completed)
    default:
      return allProspects
  }
}

const Home = () => {
  const dispatch = useDispatch();
  const [prospects, user] = useSelector((state) => [state.prospects.prospects, state.users.user]);
  const [showModal, setShowModal] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState(FILTER_TYPES.SHOW_ALL);
  const [filteredProspects, setFilteredProspects] = useState(prospects)
  const companyDomain = getCompanyDomain(user.email)
  const filterOptions = getFilterOptions(companyDomain === COMPANY_DOMAINS.AGILLIC)

  useEffect(() => {
    API.getProspects(companyDomain).then((prospects) => {
      dispatch(setProspects({ prospects }));
    });

    API.getProviders().then((providers) => {
      dispatch(setProviders({ providers }));
    });

    API.getQuestions('en').then((questions) => {
      dispatch(setQuestions({ questions }));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredProspects(getFilteredProspects(prospects, filterCriteria, user))
  }, [filterCriteria, prospects, user])

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex-auto">
      <div className="bg-white px-4 py-5 border-gray-200 sm:px-6 rounded-md shadow-lg">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Prospects ({filteredProspects.length})
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <Select
                className='inline-block mr-2 w-[230px]'
                options={filterOptions}
                initialValue={filterOptions[0]}
                onChange={({id}) => setFilterCriteria(id)}
              />
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setShowModal(true)}
              >
                Create new prospect
              </button>
            </div>
          </div>
        </div>
        <ProspectList prospects={filteredProspects} />
      </div>

      {showModal && (
        <CreateProspectModal onCancel={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default Home;
