const Feedback = ({message}) => {
    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-12 w-auto"
                        src="/images/Agillic-Multichrome-150x60-1.svg"
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-500">
                        {message}
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default Feedback;