import { Skeleton } from "antd";

const DisadvantagesCard = ({ provider, loading }) => {
  return (
    <div>
      <div className="mt-4 bg-white px-6 py-5 border-b border-gray-200 rounded-md">
        <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
          <Skeleton active paragraph={{ rows: 2 }} loading={loading}>
            <h3 className="text-xl leading-6 font-medium text-gray-900 py-1">
              Talking points to win with Agillic against {provider?.name}
            </h3>
            <ul className="list-disc px-4">
              {provider?.disadvantages.map((text, index) => {
                return (
                  <li
                    className="mt-1 text-sm text-gray-600 line-clamp-2"
                    key={index}
                  >
                    {text}
                  </li>
                );
              })}
            </ul>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default DisadvantagesCard;
