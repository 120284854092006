const locale = {
    en: {
        'provider': 'Provider',
        'providerPlaceholder': 'Select your provider',
        'name': 'Name',
        'namePlaceholder': 'Enter the name of your provider',
        'zeroChoicesLeft': 'choices left',
        'oneChoiceLeft': 'choice left',
        'multipleChoicesLeft': 'choices left',
        'next': 'Next',
        'surveyCompleted': 'Thank you for completing the survey',
    },
    de: {
        'provider': 'Anbieter',
        'providerPlaceholder': 'Wählen Sie Ihren Anbieter aus',
        'name': 'Name',
        'namePlaceholder': 'Geben Sie den Namen Ihres Anbieters ein',
        'zeroChoicesLeft': 'Auswahlmöglichkeiten übrig',
        'oneChoiceLeft': 'Wahl übrig',
        'multipleChoicesLeft': 'Möglichkeiten übrig',
        'next': 'Nächste',
        'surveyCompleted': 'Vielen Dank für die Teilnahme an dieser Umfrage!'
    }
}

export const useTranslation = () => {
    return (key, language = 'en') => locale[language][key]
}