import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Question from "../components/forms/Question";
import ButtonLarge from "../components/buttons/ButtonLarge";

import API from "../utils/firebaseAPI";
import { setSurvey } from "../store/prospectsSlice";
import { useTranslation } from "../hooks/useTranslation";

const isNextButtonDisabled = (question, answer) => {
  if (!answer) {
    return true;
  }

  if (question.type === "select") {
    return answer.isCustom && !answer.customName;
  } else {
    return answer.length < question.maxAnswers;
  }
};

const Survey = () => {
  const { sid, step } = useParams();
  const { noOfQuestions, question, survey } = useSelector((state) => ({
    noOfQuestions: state.prospects.questions.length,
    question: state.prospects.questions.find(
      (question) => question.step === +step
    ),
    survey: state.prospects.survey,
  }));
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const dispatch = useDispatch();
  const t = useTranslation()

  useEffect(() => {
    setCurrentAnswer(null);
  }, [question.id]);

  const handleNext = () => {
    const completed = +step === noOfQuestions - 1;
    const newSurvey = { ...survey, answers: { ...survey.answers } };

    if (step === "0") {
      newSurvey.provider = currentAnswer;
    } else {
      newSurvey.answers[question.step] = currentAnswer;
    }

    if (completed) {
      newSurvey.completed = completed;
    }

    API.patchSurvey(sid, newSurvey).then(() => {
      dispatch(setSurvey({ survey: newSurvey }));
    });

    window.scrollTo({ top: 0 });
  };

  return (
    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-md">
      <Question
        question={question}
        onAnswerChange={(answer) => setCurrentAnswer(answer)}
      />

      <div className="flex mt-4 justify-end">
        <Link
          to={+step < noOfQuestions - 1 ? `/surveys/${sid}/${+step + 1}` : "#"}
          onClick={handleNext}
        >
          <ButtonLarge
            text={t('next', survey.language)}
            disabled={isNextButtonDisabled(question, currentAnswer)}
          />
        </Link>
      </div>
    </div>
  );
};

export default Survey;
