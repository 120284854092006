import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import Feedback from "../components/feedback/Feedback";
import { useTranslation } from "../hooks/useTranslation";

import { setQuestions, setSurvey } from "../store/prospectsSlice";
import API from "../utils/firebaseAPI";

const SurveyBase = () => {
  const {sid} = useParams();
  const [isLoading, setIsLoading] = useState(true)

  const { survey, questions, isLoggedIn } = useSelector((state) => ({
    survey: state.prospects.survey,
    questions: state.prospects.questions,
    isLoggedIn: state.users.isLoggedIn
  }))

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const t = useTranslation()

  useEffect(() => {
    if (sid) {
      API.getSurvey(sid).then((survey) => {
        dispatch(setSurvey({ survey }))
        setIsLoading(false)
      })
      .catch((er) => {
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (survey && !survey.completed) {
      API.getQuestions(survey.language).then((newQuestions) => {
        dispatch(setQuestions({questions: newQuestions}))
        navigate(`/surveys/${sid}/0`, {replace: true})
      }) 
    }
    // eslint-disable-next-line
  }, [survey?.id])

  return (
    <div className="max-w-3xl mx-auto sm:px-6 lg:px-8 flex-auto">
      {!isLoading && survey?.completed && <Feedback message={t('surveyCompleted', survey.language)} />}
      {!isLoading && !survey && <Feedback message="Sorry, the survey you're looking for doesn't exist anymore." />}

      {!isLoading && survey && !survey.completed && questions.length && (
        <>
          {!isLoggedIn && (
            <Feedback />
          )}
          <Outlet />
        </>
      )}
    </div>
  );
};

export default SurveyBase;
