const RadioBox = ({ index, answer, handleChoice, checked }) => {
  return (
    <div className="relative flex items-start py-4">
      <div className="min-w-0 flex-1 text-sm">
        <label
          htmlFor={index}
          className="font-medium text-gray-700 select-none"
        >
          {answer}
        </label>
      </div>
      <div className="ml-3 flex items-center h-5">
        <input
          id={index}
          name={answer}
          type="checkbox"
          className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
          onClick={handleChoice}
          checked={checked}
        />
      </div>
    </div>
  );
};

export default RadioBox;
