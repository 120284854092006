import DisadvantagesCard from "./cards/DisadvantagesCard";
import CapabilitiesCard from "./cards/CapabilitiesCard";
import CapabilitiesStatusCard from "./cards/CapabilitiesStatusCard";

const SurveyOverview = ({
  prospect,
  agillicProvider,
  otherProvider,
  capabilityGroups,
  loading
}) => {
  return (
    <>
      <DisadvantagesCard provider={otherProvider} loading={loading}/>
      <div className="flex gap-3 py-4 lg:gap-6">
        <div className="w-1/3">
          <div className="bg-white px-6 py-2 border-b border-gray-200 rounded-md">
            <h3 className="text-xl leading-6 font-medium text-gray-900 py-1">
              {prospect?.name} answers
            </h3>
          </div>

          {capabilityGroups.map((capabilityGroup, index) => {
            return (
              <CapabilitiesCard
                key={index}
                capabilities={capabilityGroup.capabilities}
                title={capabilityGroup.capabilityText}
                loading={loading}
              />
            );
          })}
        </div>
        <div className="w-1/3">
          <div className="bg-white px-6 py-2 border-b border-gray-200 rounded-md">
            <img
              className="h-8 w-auto"
              src="/images/Agillic-Multichrome-150x60-1.svg"
              alt="Workflow"
            />
          </div>

          {capabilityGroups.map(({ capabilities }, index) => {
            return (
              <CapabilitiesStatusCard
                key={index}
                capabilities={capabilities}
                provider={agillicProvider}
                loading={loading}
              />
            );
          })}
        </div>
        <div className="w-1/3">
          <div className="bg-white px-6 py-2 border-b border-gray-200 rounded-md h-[49px]">
            <strong className="text-xl">
              {otherProvider?.name ||
                (otherProvider?.isCustom &&
                  `Other ( ${otherProvider?.customName} )`)}
            </strong>
          </div>

          {capabilityGroups.map(({ capabilities }, index) => {
            return (
              <CapabilitiesStatusCard
                key={index}
                capabilities={capabilities}
                provider={otherProvider}
                loading={loading}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SurveyOverview;
