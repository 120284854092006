import {
  CalendarIcon,
  OfficeBuildingIcon,
  LinkIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { message, Tooltip } from "antd";
import { getLanguageIcon } from "../../utils/common";

const handleCopySurveyLink = (event, prospect) => {
  const link = `/surveys/${prospect.surveyId}`

  event.preventDefault();
  navigator.clipboard.writeText(`${window.location.origin}${link}`);
  message.success('Copied survey link to clipboard!');
};

const ProspectList = ({ prospects }) => {
  return (
    <ul className="divide-y divide-gray-200">
      {prospects.map((prospect) => (
        <li key={prospect.id}>
          <Link to={`/prospects/${prospect.id}`} className="block group hover:bg-gray-50">
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-indigo-600 truncate">
                  <span className="mr-3">{getLanguageIcon(prospect.language)}</span> {prospect.name}
                </p>
                <div className="ml-2 flex-shrink-0 flex">
                  {!prospect.completed && (
                    <Tooltip title="Copy survey link">
                      <button
                        className="hidden group-hover:block"
                        onClick={(event) =>
                          handleCopySurveyLink(event, prospect)
                        }
                      >
                        <LinkIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>
                  )}
                  <p
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      prospect.completed
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {prospect.completed ? "Done" : "Waiting"}
                  </p>
                </div>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">
                  <p className="flex items-center text-sm text-gray-500">
                    <OfficeBuildingIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {prospect.category}
                  </p>
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>
                    Created on{" "}
                    <time dateTime={prospect.createdDate}>
                      {new Date(prospect.createdDate).toLocaleDateString()}
                    </time>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </li>
      ))}
      {prospects.length === 0 && 
        <div className="px-4 py-4 sm:px-6">
          No prospect matches your filter criteria.
        </div>
      }
    </ul>
  );
};

export default ProspectList;
