import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

const SignIn = () => {
    const { login, logout } = useAuth()

    useEffect(() => {
      logout()
      // eslint-disable-next-line
    }, [])

    const handleOnClick = (event) => {
        event.preventDefault()
        login()
    }
    return (
    <>
        <div className="flex flex-col justify-center py-36 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/images/Agillic-Multichrome-150x60-1.svg"
            alt="Workflow"
          />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          
          <button
                  type="submit"
                  className="flex justify-center mx-auto py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleOnClick}
                >
                  Sign in with Google
          </button>
          
        </div>
      </div>
    </>
  )
};

export default SignIn;
