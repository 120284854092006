import { useEffect, useState } from "react";
import { useTranslation } from "../../hooks/useTranslation";
import RadioBox from "./RadioBox";
import Select from "./Select";

const getChoicesObj = (options) =>
  options.reduce((acc, option) => {
    acc[option.id] = false;
    return acc;
  }, {});

const sum = (obj) => Object.values(obj).reduce((a, b) => a + b);

const getSelectedOptionIds = (obj) =>
  Object.entries(obj)
    .filter(([_, checked]) => checked)
    .reduce((acc, [id]) => [...acc, id], []);

const choicesLeftLocale = {
    0: 'zeroChoicesLeft',
    1: 'oneChoiceLeft'
}

const Question = ({ question, onAnswerChange }) => {
  const [selectValue, setSelectValue] = useState({});
  const [choicesObj, setChoicesObj] = useState(getChoicesObj(question.options));
  const t = useTranslation();

  useEffect(() => {
    setChoicesObj(getChoicesObj(question.options));
    setSelectValue({});

    // eslint-disable-next-line
  }, [question.id]);

  const handleChoice = async (e) => {
    if (sum(choicesObj) >= question.maxAnswers && e.target.checked === true) {
      return e.preventDefault();
    }

    const newChoicesObj = { ...choicesObj, [e.target.id]: e.target.checked };
    setChoicesObj(newChoicesObj);
    onAnswerChange(getSelectedOptionIds(newChoicesObj));
  };

  const handleSelectChange = (value) => {
    if (value.id === selectValue.id) {
      return;
    }

    onAnswerChange(value);
    setSelectValue(value);
  };

  const handleCustomNameChange = (event) => {
    onAnswerChange({ ...selectValue, customName: event.target.value });
  };

  const choicesLeft = question.maxAnswers - sum(choicesObj)
  
  return (
    <div className="ml-4 mt-2">
      <fieldset>
        <legend className="text-lg font-medium text-gray-900">
          {question.question}
        </legend>

        {question.type === "select" && (
          <>
            <label
              htmlFor="provider"
              className="block mt-4 text-sm font-medium text-gray-700"
            >
              {t('provider', question.language)}
            </label>
            <Select
              id="provider"
              className="mt-1"
              options={question.options}
              onChange={handleSelectChange}
              placeholder={t('providerPlaceholder', question.language)}
            />

            {selectValue.isCustom && (
              <>
                <label
                  htmlFor="name"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  {t('name', question.language)}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={t('namePlaceholder', question.language)}
                  autoComplete="off"
                  onChange={handleCustomNameChange}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </>
            )}
          </>
        )}

        {question.type !== "select" && (
          <>
            <div className="text-xs mt-5">
              {choicesLeft} {t(choicesLeftLocale[choicesLeft] || 'multipleChoicesLeft', question.language)}
            </div>
            <div className="mt-4 border-b border-gray-200 divide-y divide-gray-200">
              {question.options.map(({ text, id }) => {
                return (
                  <RadioBox
                    key={`${question.id}-${id}`}
                    answer={text}
                    checked={choicesObj[id]}
                    handleChoice={handleChoice}
                    index={id}
                  />
                );
              })}
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default Question;
