import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { message } from "antd";
import API from "../utils/firebaseAPI";
import { Dropdown } from "../components/Dropdown";
import SurveyOverview from "../components/SurveyOverview";
import Modal from "../components/overlays/modal";
import { getLanguageIcon } from "../utils/common";
import { removeProspect } from "../store/prospectsSlice";

const capabilityTypes = {
  functionalities: 0,
  channels: 1,
  paidChannels: 2,
  integrations: 3,
  securityFeatures: 4,
};

const Prospect = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [survey, setSurvey] = useState(null);
  const { prospect, providers, questions } = useSelector((state) => ({
    prospect: state.prospects.prospects.find((prospect) => prospect.id === id),
    providers: state.prospects.providers,
    questions: state.prospects.questions,
  }));
  const [capabilityGroups, setCapabilityGroups] = useState([{}, {}, {}]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (prospect?.surveyId && prospect.completed) {
      API.getSurvey(prospect.surveyId).then((survey) => {
        const newCapabilityGroups = questions
          .slice(1)
          .map((question) => {
            const capabilities = survey.answers[question.step].map((optionId) =>
              question.options.find((option) => option.id === optionId)
            );

            return {
              capabilities,
              capabilityText: question.capabilityText,
              type: question.type,
            };
          })
          .sort(
            (c1, c2) => capabilityTypes[c1.type] - capabilityTypes[c2.type]
          );

        setCapabilityGroups(newCapabilityGroups);
        setSurvey(survey);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [prospect?.surveyId]);

  const surveyProvider = survey?.provider;
  const agillicProvider = providers.find((provider) => provider.isAgillic);

  const otherProvider =
    providers.find((provider) => provider.id === surveyProvider?.id) ||
    surveyProvider;

  const menuItems = [
    ...(prospect?.completed
      ? []
      : [
          {
            name: "Copy survey link",
            onClick: () => {
              navigator.clipboard.writeText(
                `${window.location.origin}/surveys/${prospect?.surveyId}`
              );
              message.success("Copied survey link to clipboard!");
            },
          },
          {
            name: "Go to survey page",
            onClick: () => navigate(`/surveys/${prospect?.surveyId}`),
          },
        ]),
    {
      name: "Delete",
      onClick: () => setShowConfirmDialog(true),
    },
  ];

  const handleDeleteProspect = () => {
    API.deleteProspect(prospect).then(() => {
      setShowConfirmDialog(false)
      dispatch(removeProspect({prospect}))
      navigate('/')
    })
  }

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex-auto">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-md">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl leading-6 font-medium text-gray-900">
            <span className="mr-3">{getLanguageIcon(prospect?.language)}</span> {prospect?.name}
          </h2>
          <div className="ml-2 flex-shrink-0 flex">
            <p
              className={`px-2 inline-flex text-s leading-8 font-semibold rounded-full ${
                prospect?.completed
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }`}
            >
              {prospect?.completed ? "Done" : "Waiting"}
            </p>
            <Dropdown
              button={
                <DotsVerticalIcon className="ml-1.5 h-8 w-8 text-gray-400 p-1" />
              }
              menuItems={menuItems}
            />
          </div>
        </div>
      </div>

      {prospect?.completed && (
        <SurveyOverview
          prospect={prospect}
          agillicProvider={agillicProvider}
          otherProvider={otherProvider}
          capabilityGroups={capabilityGroups}
          loading={loading}
        />
      )}

      {showConfirmDialog && (
        <Modal
          title="Are you sure?"
          onCancel={() => setShowConfirmDialog(false)}
          onOk={handleDeleteProspect}
          okText="Delete"
          okButtonProps={{
            className: "bg-red-600 hover:bg-red-700 focus:ring-red-500",
          }}
        >
          <p className="my-10">
            Are you sure you want to delete <i>{prospect.name}</i> ?
          </p>
        </Modal>
      )}
    </div>
  );
};

export default Prospect;
