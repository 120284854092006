import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Survey from "./pages/Survey";
import Prospect from "./pages/Prospect";
import SurveyBase from "./pages/SurveyBase";
import SignIn from "./pages/SignIn";
import { RequireAuth } from "./components/navigation/RequireAuth";

import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/message/style/index.css';
import 'antd/lib/skeleton/style/index.css';

import "./App.css";

function App() {
  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="prospects/:id"
          element={
            <RequireAuth>
              <Prospect />
            </RequireAuth>
          }
        />
        <Route path="surveys/:sid" element={<SurveyBase />}>
          <Route path=":step" element={<Survey />} />
        </Route>
        <Route path="/login" element={<SignIn />} />
      </Routes>
    </Layout>
  );
}

export default App;
