import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addProspect } from "../../store/prospectsSlice";
import Select from "../forms/Select";
import Modal from "./modal";
import API from "../../utils/firebaseAPI";

const categories = [
  { id: "0", text: "Associations, charities & NGOs" },
  { id: "1", text: "Energy & utility" },
  { id: "2", text: "Entertainment & gaming" },
  { id: "3", text: "Finance" },
  { id: "4", text: "Fitness & wellness" },
  { id: "5", text: "Media & publishing" },
  { id: "6", text: "Public" },
  { id: "7", text: "Retail" },
  { id: "8", text: "Technology, software, cloud" },
  { id: "9", text: "Travel, hospitality & leisure" },
  { id: "10", text: "Other" },
];

const languages = [
  { id: "en", text: "English" },
  { id: "de", text: "German" },
];

const CreateProspectModal = ({ onCancel }) => {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState(languages[0].id);
  const user = useSelector((state) => state.users.user)

  const dispatch = useDispatch();
  const handleOnOk = () => {
    API.postProspect({ name, website, category, language, user }).then((prospect) => {
      dispatch(addProspect({ prospect }));
    });
    onCancel();
  };

  return (
    <Modal
      title="Create Prospect"
      okText="Create Prospect"
      onCancel={onCancel}
      onOk={handleOnOk}
    >
      <form action="/prospects" method="POST">
        <div className="py-5 bg-white space-y-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              autoComplete="off"
              onChange={(event) => setName(event.target.value)}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 sm:col-span-2">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium text-gray-700"
              >
                Website
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  http://
                </span>
                <input
                  type="text"
                  name="company-website"
                  id="company-website"
                  className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                  placeholder="www.example.com"
                  onChange={(event) => setWebsite(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="about"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <div className="mt-1">
              <Select
                options={categories}
                placeholder="Select a category"
                onChange={({ text }) => setCategory(text)}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="about"
              className="block text-sm font-medium text-gray-700"
            >
              Language
            </label>
            <div className="mt-1">
              <Select
                options={languages}
                placeholder={languages[0].text}
                onChange={({ id }) => setLanguage(id)}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateProspectModal;
