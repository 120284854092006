import { db } from "./firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { COMPANY_DOMAINS, getCompanyDomain } from "./common";

const provider = new GoogleAuthProvider();
const auth = getAuth();

const COLLECTION_TYPES = {
  PROSPECTS: "prospects",
  PROVIDERS: "providers",
  QUESTIONS: "questions",
  SURVEYS: "surveys",
};

const login = () => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      const user = result.user;

      if ([COMPANY_DOMAINS.AGILLIC, COMPANY_DOMAINS.BLUPLANET].find((domain) => user.email.includes(domain))){
        return { user, token }
      }

      throw new Error('Email domain is not valid')
    })
};

const getSurvey = async (id) => {
  const survey = await getDoc(doc(db, COLLECTION_TYPES.SURVEYS, id));

  if (survey.exists()) {
    return { id: survey.id, ...survey.data() };
  } else {
    return null;
  }
};

const patchSurvey = async (id, partial) => {
  const surveyRef = doc(db, COLLECTION_TYPES.SURVEYS, id);

  if (partial.completed) {
    const prospectRef = doc(db, COLLECTION_TYPES.PROSPECTS, partial.prospectId);

    await updateDoc(prospectRef, { completed: true });
  }

  return updateDoc(surveyRef, partial);
};

const getQuestions = async (language) => {
  const questionsQuery = query(collection(db, COLLECTION_TYPES.QUESTIONS), where("language", "==", language));

  const questions = await getDocs(questionsQuery);
  return questions.docs.map((doc) => ({ id: doc.id, ...doc.data() })).sort((a, b) => a.step - b.step);
};

const getProspects = async (companyDomain) => {
  const queryConstraints = [orderBy("createdDate")]

  if (companyDomain !== COMPANY_DOMAINS.AGILLIC) {
    queryConstraints.push(where('createdBy.companyDomain', '==', companyDomain))
  }

  const prospects = await getDocs(query(collection(db, COLLECTION_TYPES.PROSPECTS), ...queryConstraints))

  return prospects.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const postProspect = async ({ name, website, category, language, user }) => {
  const prospectId = doc(collection(db, COLLECTION_TYPES.PROSPECTS)).id;

  const surveyRef = await addDoc(collection(db, COLLECTION_TYPES.SURVEYS), {
    language,
    completed: false,
    answers: {},
    prospectId,
  });

  const prospectData = {
    name,
    website,
    category,
    language,
    surveyId: surveyRef.id,
    createdDate: Date.now(),
    createdBy: { userUid: user.uid, companyDomain: getCompanyDomain(user.email) },
  };

  await setDoc(doc(db, COLLECTION_TYPES.PROSPECTS, prospectId), prospectData);

  return { id: prospectId, ...prospectData };
};

const deleteProspect = async ({id, surveyId}) => {
  return Promise.all([
    deleteDoc(doc(db, COLLECTION_TYPES.SURVEYS, surveyId)),
    deleteDoc(doc(db, COLLECTION_TYPES.PROSPECTS, id))
  ])
}

const getProviders = async () => {
    const providers = await getDocs(collection(db, COLLECTION_TYPES.PROVIDERS));

    return providers.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const API = {
  login,
  getSurvey,
  patchSurvey,
  getQuestions,
  getProspects,
  postProspect,
  deleteProspect,
  getProviders,
};

export default API;
