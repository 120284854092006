import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prospects: [],
  survey: {},
  questions: [],
  providers: [],
};
export const prospectsSlice = createSlice({
  name: "prospects",
  initialState,
  reducers: {
    addProspect: (state, action) => {
      state.prospects.push(action.payload.prospect);
    },
    removeProspect: (state, action) => {
      state.prospects = state.prospects.filter(prospect => prospect.id !== action.payload.prospect.id)
    },
    setProspects: (state, action) => {
      state.prospects = action.payload.prospects;
    },
    setSurvey: (state, action) => {
      state.survey = action.payload.survey;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload.questions;
    },
    setProviders: (state, action) => {
      state.providers = action.payload.providers;
    },
  },
});

export const {
  addProspect,
  removeProspect,
  setProspects,
  setProviders,
  setSurvey,
  setQuestions,
} = prospectsSlice.actions;

export default prospectsSlice.reducer;
