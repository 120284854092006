import {
  CheckCircleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { Skeleton } from "antd";

const statusIcons = {
  yes: (
    <CheckCircleIcon
      className="flex-shrink-0 mr-1.5 h-5 w-5 stroke-green-500"
      aria-hidden="true"
    />
  ),
  no: (
    <MinusCircleIcon
      className="flex-shrink-0 mr-1.5 h-5 w-5 stroke-red-500"
      aria-hidden="true"
    />
  ),
  maybe: (
    <InformationCircleIcon
      className="flex-shrink-0 mr-1.5 h-5 w-5 stroke-yellow-500"
      aria-hidden="true"
    />
  ),
  unknown: (
    <QuestionMarkCircleIcon
      className="flex-shrink-0 mr-1.5 h-5 w-5 stroke-gray-500"
      aria-hidden="true"
    />
  ),
};

const getStatusIcon = (provider, capability) => {
  return provider?.isCustom
    ? statusIcons.unknown
    : statusIcons[provider?.capabilities[capability.id] || "no"];
};

const CapabilitiesStatusCard = ({ capabilities, provider, loading }) => {
  return (
    <div className="bg-white mt-1 px-4 py-2 border-b border-gray-200 rounded-md">
      <ul className="divide-y divide-gray-200 mt-[32px]">
        <Skeleton
          className="mt-14"
          title={false}
          paragraph={{ rows: 9 }}
          loading={loading}
        >
          {capabilities?.map((capability, index) => {
            return (
              <li
                key={index}
                className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
              >
                <p className="text-sm text-gray-500 truncate">
                  {getStatusIcon(provider, capability)}
                </p>
              </li>
            );
          })}
        </Skeleton>
      </ul>
    </div>
  );
};

export default CapabilitiesStatusCard;
